<template>
  <div id="component-list-shops">
    {{/* CARD SHOP */}}
    <div class="card card-border">
      <div class="card-header p-0 bg-white">
        <div class="ml-3 mt-3 mb-3">
          <h5>Choisir un magasin :</h5>
        </div>
      </div>
      <div class="card-body specific-height">
        <div v-if="listShopsCenter.length > 0">
          <Isotope
              :list="listShopsCenter"
              :options="isotope.options"
              @layout="isotope.currentLayout"
              class="iso-default"
          >
          <div v-for="(shop, index) in listShopsCenter" :key="index" class="ml-2 mt-2">
            <div
                class="card c-pointer"
                :class="[
                  (selectShop && selectShop === shop.id) ? 'shop-active' : '',
                  (firstSelectShop && firstSelectShop.id === shop.id) ? 'shop-disabled' : '',
                  handleDisabledShop(shop)
                ]"
                v-on:click="emitSelectShop"
                :data-id="shop.id"
            >
              <div class="card-body p-2">
                <b-icon
                    icon="shop"
                    font-scale="1.5"
                    variant="warning"
                    class="icon-folder mr-2"
                    v-on:click="emitSelectShop"
                    :data-id="shop.id"
                ></b-icon>
                <span v-on:click="emitSelectShop" :data-id="shop.id">
                  {{ shop.name }}
                </span>
              </div>
            </div>
          </div>
          </Isotope>
        </div>
        <div class="text-center text-secondary" v-else>Aucun Magasin</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    listShopsCenter: Array,
    listSelectedShop: Array,
    firstSelectShop: Object,
    selectCenter: Number,
    selectShop: Number,
  },
  data() {
    return {
      isotope: {
        currentLayout: 'masonry',
        selectLayout: null,
        layouts: [
          "masonry"
        ],
        containerStyle: {
          overflow: 'auto'
        },
        options: {
          masonry: {
            columnWidth: 30
          }
        }
      },
    }
  },
  methods: {
    handleDisabledShop(shop) {
      let shopDisabledCssClass = null

      if (this.listSelectedShop) {
        this.listSelectedShop.forEach((shopObj) => {
          if (shopObj.shop.id === shop.id) {
            shopDisabledCssClass = 'shop-disabled'
          }
        })
      }

      return shopDisabledCssClass
    },
    emitSelectShop($event) {
      this.$emit('emit-select-shop', $event)
    },
  },
  computed: {
    ...mapState(['ShopModule']),
  },
  components: {
    Isotope: () => import('vueisotope')
  }
}
</script>

<style scoped>

</style>
